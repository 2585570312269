import React from 'react';
import { Switch, Route } from 'react-router-dom';

const NotificationPage = React.lazy(() => import(/* webpackChunkName: "NotificationsPage" */ './Pages/NotificationsPage'));
const DashboardPage = React.lazy(() => import(/* webpackChunkName: "DashboardPage" */ './Pages/DashboardPage'));
const ComponentPageNotFound = React.lazy(() => import(/* webpackChunkName: "ComponentNotFound" */ './Pages/ComponentNotFound'));

const Router = () => {

  return (
    <Switch>
      <Route
        path='/notifications/:entityId?'
        component={({ history, match: { params: { entityId } }, location: { search, pathname } }) => <NotificationPage entityId={entityId} history={history} pathname={pathname} search={search} />}
      />
      <Route path='/' component={DashboardPage} />
      <Route path='*' component={ComponentPageNotFound} />
    </Switch>
  );

};

export default Router;
