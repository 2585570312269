import React, { useCallback, useContext } from 'react';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { Icon, Badge } from 'antd';
import { Link } from 'react-router-dom';
import css from './notificationBell.scss';
import { AppContext } from 'utils/AppContext';
import {GET_NOTIFICATIONS, SUBSCRIBE_NOTIFICATIONS} from './queries'

const NotificationBell = () => {
  const { user } = useContext(AppContext);
  const { data: notificationsResponse } = useQuery(GET_NOTIFICATIONS);
  const { data: subscriptionData } = useSubscription(SUBSCRIBE_NOTIFICATIONS);

  const getNotificationsCount = useCallback(() => {
    const payloadToUser = subscriptionData || notificationsResponse
    if (!payloadToUser || !payloadToUser.notifications) return 0;

    const userId: string = user ? user.id : '-';
    return (payloadToUser.notifications.data.all || 0) + (payloadToUser.notifications.data[userId] || 0);

  }, [notificationsResponse, subscriptionData]);

  return (
    <Link to={'/notifications?notes_conditions={"querySearch":"isReminder:true+and+isDone:false"}&state=true&type=true'}>
      <Badge count={  getNotificationsCount()} className={css.badge} overflowCount={100}>
        <Icon type="bell" />
      </Badge>
    </Link>
  )
}

export default React.memo(NotificationBell);
