exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir=rtl] * {\n  text-align: right !important; }\n\n.App___wmyqV {\n  text-align: center; }\n\n.ant-form-item___2oMcI.ant-form-item-view-mode___2sBVI {\n  margin-bottom: 0px !important; }\n\n.ant-form-item-label___3OIqW {\n  padding: 0px !important; }\n\n.ant-form-vertical___nokkJ .ant-form-item___2oMcI {\n  padding-bottom: 0px !important;\n  margin-bottom: 10px !important; }\n\n#root___18MbG > div > div.ant-tabs-bar___zzfua {\n  margin: 0px;\n  padding: 0px; }\n\n.ant-layout___1RDek {\n  min-height: calc(100vh - 92px); }\n\n.App-logo___4EwP9 {\n  height: 40vmin; }\n\n.App-header___1ani9 {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white; }\n\n.App-link___2NVXP {\n  color: #09d3ac; }\n", ""]);

// Exports
exports.locals = {
	"App": "App___wmyqV",
	"ant-form-item": "ant-form-item___2oMcI",
	"ant-form-item-view-mode": "ant-form-item-view-mode___2sBVI",
	"ant-form-item-label": "ant-form-item-label___3OIqW",
	"ant-form-vertical": "ant-form-vertical___nokkJ",
	"root": "root___18MbG",
	"ant-tabs-bar": "ant-tabs-bar___zzfua",
	"ant-layout": "ant-layout___1RDek",
	"App-logo": "App-logo___4EwP9",
	"App-header": "App-header___1ani9",
	"App-link": "App-link___2NVXP"
};