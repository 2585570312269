import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    notifications {
      data
    }
  }
`

export const SUBSCRIBE_NOTIFICATIONS = gql`
  subscription getNotifications {
    notifications {
      data
    }
  }
`
